import axios, { type AxiosResponse } from "axios";
import { env } from "../environments/env";

export interface QueryInputType<T> {
  urlParams?: string[];
  requestParams?: T;
}

export enum RequestType {
  Post = "POST",
  Delete = "DELETE",
  Update = "UPDATE",
}

const isOperator = window?.location?.pathname?.includes("/operator/station");

export const baseUrl = isOperator
  ? env.REACT_APP_API_OPERATOR_URL
  : env.REACT_APP_API_URL;

const $api = axios.create({
  baseURL: baseUrl,
});

const mutation =
  <InputType, OutputType>(requestType?: RequestType) =>
  async (
    url: string,
    { arg }: Readonly<{ arg: InputType }>,
  ): Promise<AxiosResponse<OutputType>> => {
    switch (requestType) {
      case RequestType.Delete: {
        return await $api.delete(url, { data: arg });
      }
      case RequestType.Update: {
        return await $api.put(url, arg);
      }
      case RequestType.Post:
      default: {
        return await $api.post(url, arg);
      }
    }
  };

const query =
  <T, OutputType>(
    input?: Readonly<QueryInputType<T>> | null,
    options?: Record<string, any>,
  ) =>
  async (url: string): Promise<AxiosResponse<OutputType>> => {
    const params = input?.requestParams ?? null;
    const urlParams = input?.urlParams?.join("/");
    return await $api.get(`${url}${urlParams ? `/${urlParams}` : ""}`, {
      ...options,
      params,
    });
  };

export default $api;
export { mutation, query };
