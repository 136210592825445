import React, { type ReactElement } from "react";
import styles from "./LogOutButton.module.scss";
import clsx from "clsx";
import { IoExitOutline } from "react-icons/io5";
import { type ILogOutButton } from "../config/types";
import { Button } from "../../Button";

export const LogOutButton = ({
  collapsed,
  onLogOutClick,
}: ILogOutButton): ReactElement => {
  return (
    <Button
      withoutIndentation
      view="transparent"
      className={styles.menuItems}
      onClick={onLogOutClick}
    >
      <IoExitOutline
        className={clsx(styles.icon, {
          [styles.icon_collapsed]: collapsed,
        })}
      />
      <div className={clsx(styles.text, { [styles.text_hide]: collapsed })}>
        Выйти
      </div>
    </Button>
  );
};
