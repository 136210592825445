import { memo, type ReactElement } from "react";
import clsx from "clsx";
import styles from "./MainLayout.module.scss";

interface MainLayoutProps {
  className?: string;
  header: ReactElement;
  content: ReactElement;
  sidebar?: ReactElement;
  isPrivatePage: boolean;
}

// eslint-disable-next-line react/display-name
export const MainLayout = memo((props: MainLayoutProps) => {
  const { className, content, header, sidebar, isPrivatePage } = props;
  return (
    <div className={clsx(styles.root, className)}>
      <header className={styles.header}>{header}</header>
      {isPrivatePage && <aside className={styles.sidebar}>{sidebar}</aside>}
      <main className={styles.content}>{content}</main>
    </div>
  );
});
