import React from "react";
import { Slider } from "antd";
import styles from "./StatusBar.module.scss";

export const StatusBar = ({
  handlePlayerSeek,
  handlePlayerSeekComplete,
  played,
  getPreviewTime,
}: Readonly<{
  handlePlayerSeek: (newValue: number) => void;
  handlePlayerSeekComplete: (newValue: number) => void;
  played: number;
  getPreviewTime: (type: "start" | "end") => string;
}>) => {
  return (
    <div className={styles.timeLine}>
      <span>{getPreviewTime("start")}</span>
      <div className={styles.sliderWrapper}>
        <Slider
          min={0}
          max={100}
          tooltip={{ formatter: null }}
          value={played * 100}
          onChange={handlePlayerSeek}
          onAfterChange={handlePlayerSeekComplete}
        />
      </div>
      <span>{getPreviewTime("end")}</span>
    </div>
  );
};
