import React, { type SVGProps, type VFC } from "react";
import { Tooltip } from "antd";
import { Button } from "../../../index";

export const ButtonWithTooltip = ({
  onClick,
  title,
  Svg,
  className,
}: Readonly<{
  onClick?: () => void;
  title: string;
  Svg: VFC<SVGProps<SVGSVGElement>>;
  className: string;
}>) => {
  return (
    <Tooltip title={title}>
      <Button view="transparent" withoutIndentation onClick={onClick}>
        <Svg className={className} />
      </Button>
    </Tooltip>
  );
};
