import { Table } from "antd";
import clsx from "clsx";
import "./styles/Table.scss";
import React from "react";
import styles from "./Table.module.scss";
import { type ITableProps } from "../model/types/types";
import { Loader } from "../../Loader";

export const TableComponent: React.FC<ITableProps<any>> = ({
  data = [],
  columns = [],
  pagination,
  handleChange,
  currentRow,
  onRowClick,
  rowId,
  rowClassName,
  className,
  loading = false,
  rowSelection,
  scroll,
  virtual = true,
  ...props
}) => {
  const isActiveRow = (record: Readonly<Record<string, any>>) =>
    currentRow && record[rowId] === currentRow[rowId];

  const rowClassNames = (record: Readonly<Record<string, any>>) =>
    clsx(styles.row, { [styles.row_active]: isActiveRow(record) });

  const loadingIndicator = {
    indicator: <Loader />,
  };

  return (
    <Table
      {...props}
      rowKey={(record: Readonly<Record<string, any>>) => record[rowId]}
      virtual={virtual}
      columns={columns}
      dataSource={data}
      onChange={handleChange}
      rowSelection={rowSelection && { ...rowSelection, checkStrictly: false }}
      rowClassName={rowClassName}
      className={className}
      loading={loading ? loadingIndicator : false}
      onRow={(record) => {
        return {
          onClick: () => {
            onRowClick && onRowClick(record);
          },
          className: rowClassNames(record),
        };
      }}
      scroll={scroll}
      pagination={pagination}
    />
  );
};
