import React, {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from "react";
import { type ILineItem } from "../../../pages/DashboardPage/config/types";

export interface LineWarningsContextType {
  setCurrentLine: Dispatch<SetStateAction<ILineItem | null>>;
  currentLine: ILineItem | null;
}

const LineWarningsContext = createContext<LineWarningsContextType>({
  setCurrentLine: () => {},
  currentLine: null,
});

export const useLineWarningsLibs = () => {
  return useContext(LineWarningsContext) as Required<LineWarningsContextType>;
};

export const LineWarningsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentLine, setCurrentLine] = useState<ILineItem | null>(null);

  return (
    <LineWarningsContext.Provider
      value={{
        currentLine,
        setCurrentLine,
      }}
    >
      {children}
    </LineWarningsContext.Provider>
  );
};

export default LineWarningsContext;
