import React, { Suspense, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { type AppRoutesProps } from "../../../../shared/types/router";
import { RequireAuth } from "./RequireAuth";
import { routeConfig } from "../config/routeConfig";
import { Loader } from "../../../../modules/components/shared";

const AppRouter = () => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const element = <Suspense fallback={<Loader />}>{route.element}</Suspense>;

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          route.authOnly ? (
            <RequireAuth roles={route.roles}>{element}</RequireAuth>
          ) : (
            element
          )
        }
      />
    );
  }, []);

  return <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>;
};

export default AppRouter;
