import React from "react";
import styles from "./Header.module.scss";
import { Typography } from "../../../shared";
import { DataTime } from "./components/DataTime/DataTime";
import { type HeaderType } from "../config/types/types";

const Header: React.FC<HeaderType> = (props) => {
  const {
    isAuthenticated = false,
    logo = "/assets/logo/logo.png",
    title = "Эффективный рабочий",
    position,
    name,
  } = props;

  const makeIcon = (icon: HeaderType["logo"]) =>
    typeof icon === "string" ? (
      <img src={icon} width={173} height={52} alt="logo" />
    ) : (
      icon
    );

  return (
    <article className={styles.header}>
      <div className={styles.logoWrapper}>
        {makeIcon(logo)}
        <Typography className={styles.title}>{title}</Typography>
      </div>

      <section className={styles.textWrapper}>
        {isAuthenticated && (
          <>
            <div className={styles.userWrapper}>
              <Typography
                component="p"
                hidden={!position}
                className={styles.role}
              >
                {position}
              </Typography>
              <Typography
                component="p"
                type="h1"
                hidden={!name}
                className={styles.name}
              >
                {name}
              </Typography>
            </div>
            <DataTime />
          </>
        )}
      </section>
    </article>
  );
};
export default Header;
