import { USER_LOCALSTORAGE_KEY } from "../../const/localstorage";
import { useAuth } from "../../config/context/useAuth";
import $api from "../../api";

export const useOnLogOutClick = (): {
  onLogOutClick: (exitByServer?: boolean) => void;
} => {
  const { setAuth } = useAuth();

  const onLogOutClick = (): void => {
    localStorage.removeItem(USER_LOCALSTORAGE_KEY);
    $api.defaults.headers.common.Authorization = `Basic`;
    setAuth(false);
  };

  return { onLogOutClick };
};
