import { TimePicker } from "antd";
import "./styles/TimePicker.scss";
import React from "react";

export const TimePickerComponent: React.FC<Record<string, any>> = ({
  popupClassName,
  className,
  value,
  format = "HH:mm",
  ...props
}) => {
  return (
    <TimePicker
      {...props}
      value={value}
      className={className}
      format={format}
      popupClassName={popupClassName}
    />
  );
};
