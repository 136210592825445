import React from "react";
import styles from "./DataTime.module.scss";
import { Typography } from "../../../../../shared";
import { useGetDate } from "../../../lib/hooks/useGetDate";

export const DataTime: React.FC = () => {
  const { time, dateNow } = useGetDate();

  return (
    <div className={styles.dataWrapper}>
      <Typography component="p" type="h1" className={styles.date}>
        Дата: <Typography>{dateNow}</Typography>
      </Typography>
      <Typography component="p" type="h1" className={styles.date}>
        Время: <Typography>{time}</Typography>
      </Typography>
    </div>
  );
};
