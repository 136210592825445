export enum AppRoutes {
  LOGIN = "login",
  NOT_FOUND = "not_found",
  // EXIT = "exit",
  DASHBOARD = "dashboard",
  MAIN_DASHBOARD = "main_dashboard",
  BOX_DEFECTS = "box_defects",
  CONSISTENCY = "consistency",
  LABEL = "label",
  REPORTS = "reports",
  PACKEGE_DISTRIBUTION = "package_distribution",
  DASHBOARD_OPERATOR = "dashboard_operator",
}

export const getRouteLogin = () => "/login";
export const getRouteDashboard = () => "/dashboard";
export const getRouteMainDashboard = () => "/";
export const getRouteReports = () => "/reports";
export const getRouteBoxDefects = () => "/boxDefects";
export const getRouteConsistency = () => "/consistency";
export const getRouteLabel = () => "/label";
export const getRoutePackageDistribution = () => "/packageDistribution";
export const getRouteNotFound = () => "*";
export const getRouteDashboardOperator = () => "/operator/station/:id";
