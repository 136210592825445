import clsx from "clsx";
import { type FC } from "react";
import styles from "./Loader.module.scss";

interface LoaderProps {
  className?: string;
  view?: "primary" | "white";
}

export const Loader: FC<LoaderProps> = ({ className, view = "primary" }) => {
  const classNameCircle = clsx(styles.ellipsis, {
    [styles[`ellipsis_${view}`]]: view,
  });

  return (
    <div className={clsx(styles.root, className)}>
      <div className={classNameCircle}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
