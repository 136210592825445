import { Select } from "antd";
import clsx from "clsx";
import {
  type DetailedHTMLProps,
  forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
} from "react";
import { type BaseSelectRef } from "rc-select";
import styles from "./Select.module.scss";
import "./styles/Select.scss";
import { type ISelectProps } from "../../../config/types/types";
import { Typography } from "../../Typography";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SelectComponent = forwardRef<
  BaseSelectRef,
  PropsWithChildren<ISelectProps> &
    DetailedHTMLProps<HTMLAttributes<BaseSelectRef>, BaseSelectRef>
>((props, ref): JSX.Element => {
  const {
    className,
    popupClassName,
    view = "white",
    error,
    errorClassName,
    primaryWithoutHover = false,
    ...other
  } = props;

  const rootClassName = clsx(className, {
    [styles[`root_${view}`]]: view,
    [styles.root_error]: error,
    [styles.root_primaryNotHover]: primaryWithoutHover,
  });
  const popupSelectClassName = clsx(popupClassName, {
    [styles[`dropdown_${view}`]]: view,
  });

  return (
    <div className={styles.selectWrapper}>
      <Select
        {...other}
        ref={ref}
        className={rootClassName}
        popupClassName={popupSelectClassName}
      />
      {error && (
        <Typography
          component="span"
          type="error"
          className={clsx(styles.error, errorClassName)}
        >
          {error.message}
        </Typography>
      )}
    </div>
  );
});

SelectComponent.displayName = "SelectComponent";
