import {
  type DetailedHTMLProps,
  forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
} from "react";
import clsx from "clsx";
import styles from "./Input.module.scss";
import { type InputProps } from "../../../config/types/types";
import { Typography } from "../../Typography";

export const Input = forwardRef<
  HTMLInputElement,
  PropsWithChildren<InputProps> &
    DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement>
>((props, refInput): JSX.Element => {
  const {
    type,
    className,
    errorClassName,
    disabled = false,
    placeholder,
    image,
    error,
    ...others
  } = props;
  return (
    <div className={clsx(styles.inputWrapper, className)}>
      {image && <img src={image} alt="" />}
      <input
        ref={refInput}
        type={type}
        placeholder={placeholder}
        className={styles.input}
        autoComplete="on"
        disabled={disabled}
        {...others}
      />
      {error && (
        <Typography
          component="span"
          type="error"
          className={clsx(styles.error, errorClassName)}
        >
          {error.message}
        </Typography>
      )}
    </div>
  );
});

Input.displayName = "Input";
