import { type FieldError, type FieldValues } from "react-hook-form";
import {
  type DatePickerProps,
  type MenuProps,
  type ModalProps as ModalProp,
  type SelectProps,
} from "antd";
import {
  type ButtonHTMLAttributes,
  type InputHTMLAttributes,
  type ReactNode,
  type TextareaHTMLAttributes,
} from "react";
import { type RangePickerProps } from "antd/lib/date-picker";
import { type FilterValue } from "antd/es/table/interface";
import { type DefaultOptionType } from "antd/es/select";

export enum DateFormats {
  DMY = "DD.MM.YYYY",
  DMYHms = "DD.MM.YYYY HH:mm:ss",
  dashYMD = "YYYY-MM-DD",
  request = "YYYY-MM-DD HH:mm:ss",
}

export type viewTypes =
  | "primary"
  | "secondary"
  | "link"
  | "transparent"
  | "white";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  view?: Exclude<viewTypes, "white">;
  withoutIndentation?: boolean;
}

export interface DropdownSaveProps {
  items?: MenuProps["items"];
  data?: Record<string, FilterValue | null>;
  buttonProps?: ButtonProps;
  isLoading?: boolean;
  nameButton?: string;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  errorClassName?: string;
  image?: string;
  error?: FieldError;
  type?: string;
}

export interface ISelectProps extends SelectProps {
  view?: Exclude<viewTypes, "link" | "transparent">;
  error?: FieldError;
  errorClassName?: string;
  personsSearch?: DefaultOptionType[] | null;
  customSelect?: boolean;
  primaryWithoutHover?: boolean;
}

export type IRangePickerProps = RangePickerProps & {
  view?: Exclude<viewTypes, "secondary" | "link" | "transparent">;
  popupClassName?: string;
};

export type IDatePickerProps = DatePickerProps & {
  view?: Exclude<viewTypes, "secondary" | "link" | "transparent">;
  popupClassName?: string;
};

export type TTypographyType =
  | "h1"
  | "h2"
  | "h3"
  | "p"
  | "error"
  | "title"
  | "primarySelect"
  | "secondarySelect";

type TagComponent =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "div"
  | "span"
  | "p"
  | "strong";

export interface ITypographyProps {
  component?: TagComponent;
  type?: TTypographyType;
}

export interface ModalProps extends ModalProp {
  isModalOpen?: boolean;
  onClose: () => void;
  onSubmit?: (formData: any) => void;
  showTime?: boolean;
  children?: ReactNode;
  id?: number | undefined;
  destroyOnClose?: boolean;
  preview?: string;
  className?: string;
}

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  wrapperClassName?: string;
  className?: string;
  errors?: FieldError;
}

export interface SliderComponentProps extends FieldValues {
  min?: number;
  max?: number;
  sliderName: string;
  inputValue?: number;
  onChange?: () => void;
  disabled?: boolean;
  defaultValue?: number;
  className?: string;
  inputClassName?: string;
}

export interface IPagination {
  onChange: (value: number) => void;
  currPage: number;
  totalPages?: number;
}

export type ViewProps = "primary" | "warning" | "transparent" | "offline";

export interface DashboardLayoutProps {
  headerClassName?: string;
  contentClassName?: string;
  view?: ViewProps;
  content?: string | ReactNode;
  title?: string | ReactNode;
}
