import { type ReactElement } from "react";
import clsx from "clsx";
import { Badge, Button, Typography } from "../../../shared";
import { type MenuProps } from "../config/types";
import styles from "./menu.module.scss";

export const NavMenu = ({
  items,
  activeItem,
  collapsed,
  menuSelectionEvent,
}: MenuProps): ReactElement => {
  const isSelected = (key: string): boolean => key === activeItem;

  return (
    <ul className={styles.elements}>
      {items.map((el) => (
        <li key={el.key} className={styles.item}>
          <Button
            withoutIndentation
            view="transparent"
            className={clsx(styles.menuItems, {
              [styles.menuItem_active]: isSelected(el.path),
              [styles.menuItem_collapsed]: collapsed,
            })}
            onClick={() => menuSelectionEvent(el.path)}
          >
            {el.warningsCount > 0 && <Badge warningsCount={el.warningsCount} />}
            {el.icon}
            <Typography
              className={clsx(styles.text, { [styles.text_hide]: collapsed })}
            >
              {el.text}
            </Typography>
          </Button>
        </li>
      ))}
    </ul>
  );
};
