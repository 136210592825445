import { USER_LOCALSTORAGE_KEY } from "../../const/localstorage";
import { type LoginSentResponse } from "../../../pages/LoginPage/config/types";

const getLoginData = (): LoginSentResponse => {
  const loginData = JSON.parse(
    localStorage.getItem(USER_LOCALSTORAGE_KEY) ?? "[]",
  ) satisfies {
    user: {
      username: string;
      role: {
        roleName: string;
      };
    };
    access_token: string;
  };

  return loginData;
};

export default getLoginData;
