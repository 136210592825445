import React from "react";
import { SideBarWrapper } from "../../../modules/components/shared";
import { NavMenu } from "../../../modules/components/entities";
import { MENU_ITEMS_CONFIG } from "../config/constans/MENU_ITEMS_CONFIG";
import { useLocation, useNavigate } from "react-router-dom";
import { useOnLogOutClick } from "../../../shared/lib/hooks/useOnLogOutClick";
import { LogOutButton } from "../../../modules/components/shared/components/LogOutButton/ui/LogOutButton";
import { useLineWarningsLibs } from "../../../app/providers/LineWarningsProvider/LineWarningsProvider";

const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onLogOutClick } = useOnLogOutClick();
  const { currentLine, setCurrentLine } = useLineWarningsLibs();

  const onMenuItemClick = (path: string): void => {
    if (path === "/dashboard" && currentLine) {
      setCurrentLine(null);
    }
    navigate(path);
  };

  return (
    <SideBarWrapper>
      <NavMenu
        key={MENU_ITEMS_CONFIG[0].key}
        items={MENU_ITEMS_CONFIG}
        activeItem={pathname}
        menuSelectionEvent={onMenuItemClick}
      />
      <LogOutButton
        key={MENU_ITEMS_CONFIG[1].key}
        onLogOutClick={onLogOutClick}
      />
    </SideBarWrapper>
  );
};

export default SideBar;
