import dayjs from "dayjs";
import screenfull from "screenfull";
import { useEffect, useRef, useState } from "react";
import type BaseReactPlayer from "react-player/base";
import type ReactPlayer from "react-player";
// eslint-disable-next-line import/no-unresolved
import { type OnProgressProps } from "react-player/base";
import type IPlayerState from "../interfaces/IPlayerState";
import { type IPlayerStates } from "../interfaces/IVideoComponent";
import { formatVideoTime } from "../../lib/helpers/formatVideoTime";
import { DateFormats } from "../../../../shared/config/types/types";

const usePlayerStates = ({
  autoPlay,
  onEnded,
  controlsList,
  getPlayerRef,
}: Readonly<IPlayerStates>) => {
  /**
   * REF
   */
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<BaseReactPlayer<ReactPlayer> | null>(null);

  /**
   * PLAYER DATA
   */
  const [playerState, setPlayerState] = useState<IPlayerState>({
    playing: autoPlay || false,
    volume: 0,
    muted: true,
    played: 0,
    seeking: false,
  });

  /**
   * CONTROLS
   */

  // PLAY PAUSE
  const handleChangePlaying = (): void => {
    setPlayerState((prev) => ({ ...prev, playing: !prev.playing }));
  };

  // SEEK
  const handlePlayerSeek = (newValue: number): void => {
    setPlayerState((prev) => ({
      ...prev,
      played: parseFloat((newValue / 100).toString()),
      seeking: true,
      playing: false,
    }));
    playerRef.current?.seekTo(newValue / 100);
  };

  const handlePlayerSeekComplete = (newValue: number): void => {
    setPlayerState((prev) => ({ ...prev, seeking: false, playing: true }));
    playerRef.current?.seekTo(newValue / 100);
  };

  const handlePlayerProgress = (state: Readonly<OnProgressProps>): void => {
    if (!playerState.seeking) setPlayerState((prev) => ({ ...prev, ...state }));
  };

  // FULL SCREEN
  const handleFullScreen = (): void => {
    if (!playerContainerRef.current) return;

    screenfull.toggle(playerContainerRef.current);
  };

  /**
   * END VIDEO
   */
  const handleOnEnded = (): void => {
    if (onEnded) onEnded();

    handleChangePlaying();
  };

  const playedTime = formatVideoTime(
    playerRef.current ? playerRef.current?.getCurrentTime() : 0,
  );
  const fullVideoTime = formatVideoTime(
    playerRef.current ? playerRef.current?.getDuration() : 0,
  );

  const isFullTime =
    controlsList?.timeLine?.startTime && controlsList?.timeLine?.endTime;

  const getPreviewTime = (type: "start" | "end"): string => {
    if (isFullTime) {
      return type === "start"
        ? dayjs(controlsList.timeLine?.startTime)
            .add(
              playerRef.current ? playerRef.current?.getCurrentTime() : 0,
              "second",
            )
            .format(DateFormats.DMYHms)
        : dayjs(controlsList.timeLine?.endTime).format(DateFormats.DMYHms);
    }

    return type === "start" ? playedTime : fullVideoTime;
  };

  const hasOnlyFullScreen = (): boolean => {
    const controlsListKeys = Object.keys(controlsList || {});
    return (
      controlsListKeys.length === 1 && controlsListKeys.includes("fullscreen")
    );
  };

  /**
   * PREVIEW TIME BY TIMELINE
   */

  useEffect(() => {
    const readFKeyHandler = (e: KeyboardEvent): void => {
      if (
        controlsList?.fullscreen &&
        e.code === "KeyF" &&
        playerContainerRef.current
      ) {
        screenfull.toggle(playerContainerRef.current);
      }

      if (controlsList?.playPause && e.code === "Space") {
        handleChangePlaying();
      }
    };

    playerContainerRef.current?.addEventListener("keyup", readFKeyHandler);

    return () => {
      playerContainerRef.current?.removeEventListener("keyup", readFKeyHandler);
    };
  }, []);

  useEffect(() => {
    if (getPlayerRef && playerRef) {
      getPlayerRef(playerRef);
    }
  }, [playerRef]);

  return {
    playerContainerRef,
    playerState,
    handleOnEnded,
    handlePlayerProgress,
    handleChangePlaying,
    playerRef,
    handlePlayerSeek,
    handlePlayerSeekComplete,
    handleFullScreen,
    getPreviewTime,
    hasOnlyFullScreen,
  };
};

export default usePlayerStates;
