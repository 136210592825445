declare global {
  interface Window {
    env: any;
    Hls: any;
  }
}

interface EnvType {
  REACT_APP_API_URL: string;
  REACT_APP_API_OPERATOR_URL: string;
  REACT_APP_API_DURATION_DASHBOARD_UPDATE: string | number;
}

export const env: EnvType = { ...process.env, ...window.env };
