import { type Config } from "react-player";

export const getVideoConfig = (autoPlay?: boolean): Config => {
  return {
    file: {
      attributes: {
        controls: false,
        autoPlay,
      },
    },
  };
};
