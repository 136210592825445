import ReactPlayer from "react-player";
import screenfull from "screenfull";
import clsx from "clsx";
import styles from "./VideoComponent.module.scss";
import { getVideoConfig } from "../config/getVideoConfig";
import { type IVideoComponent } from "../model/interfaces/IVideoComponent";
import usePlayerStates from "../model/hooks/usePlayerStates";
import { ButtonWithTooltip, StatusBar } from "../../../shared";
import { videoControlItems } from "../model/config/videoControlItems";
import { type ReactElement, useMemo } from "react";

export const VideoComponent = ({
  getPlayerRef,
  controlsList,
  onClickPrev,
  onClickNext,
  autoPlay,
  prevButton,
  nextButton,
  onEnded,
  children,
  isHideListControls = false,
  ...attrs
}: Readonly<IVideoComponent>): ReactElement => {
  const videoListControls = useMemo(() => {
    return controlsList;
  }, [controlsList]);

  const {
    playerContainerRef,
    playerState,
    handleOnEnded,
    handlePlayerProgress,
    handleChangePlaying,
    playerRef,
    handlePlayerSeek,
    handlePlayerSeekComplete,
    handleFullScreen,
    getPreviewTime,
    hasOnlyFullScreen,
  } = usePlayerStates({
    autoPlay,
    onEnded,
    controlsList: videoListControls,
    getPlayerRef,
  });

  return (
    <div className={styles.videoContainer} ref={playerContainerRef}>
      {children}
      <ReactPlayer
        {...attrs}
        ref={playerRef}
        className={styles.video}
        playing={playerState.playing}
        volume={playerState.volume}
        onEnded={handleOnEnded}
        onProgress={handlePlayerProgress}
        config={getVideoConfig(autoPlay)}
      />
      <div
        className={clsx(styles.videoControlsList, {
          [styles.onlyFullScreen]: hasOnlyFullScreen(),
          [styles.hideListControl]: isHideListControls,
        })}
      >
        {prevButton && (
          <ButtonWithTooltip
            className={styles.videoControlBtn}
            Svg={videoControlItems.ArrowLeftIcon}
            title={videoControlItems.ArrowLeftTitle}
            onClick={onClickPrev}
          />
        )}
        {videoListControls?.playPause && (
          <ButtonWithTooltip
            className={styles.videoControlBtn}
            Svg={
              playerState.playing
                ? videoControlItems.PlayButtonIcon
                : videoControlItems.PauseButtonIcon
            }
            title={
              playerState.playing
                ? videoControlItems.PlayButtonTitle
                : videoControlItems.PauseButtonTitle
            }
            onClick={handleChangePlaying}
          />
        )}
        {nextButton && (
          <ButtonWithTooltip
            className={styles.videoControlBtn}
            Svg={videoControlItems.ArrowRightIcon}
            title={videoControlItems.ArrowRightTitle}
            onClick={onClickNext}
          />
        )}
        {videoListControls?.timeLine && (
          <StatusBar
            played={playerState.played}
            getPreviewTime={getPreviewTime}
            handlePlayerSeek={handlePlayerSeek}
            handlePlayerSeekComplete={handlePlayerSeekComplete}
          />
        )}
        {videoListControls?.fullscreen && (
          <ButtonWithTooltip
            className={styles.fullScreenBtn}
            Svg={
              screenfull.isFullscreen
                ? videoControlItems.FullScreenButtonIcon
                : videoControlItems.DefaultedScreenButtonIcon
            }
            title={
              screenfull.isFullscreen
                ? videoControlItems.FullScreenButtonTitle
                : videoControlItems.DefaultedScreenButtonTitle
            }
            onClick={handleFullScreen}
          />
        )}
      </div>
    </div>
  );
};
