import React from "react";
import { Header as HeaderComponent } from "../../../modules/components/widgets";
import getLoginData from "../../../shared/lib/helpers/getLoginData";
import { useAuth } from "../../../shared/config/context/useAuth";
import useRoles from "../../../app/providers/router/lib/useRoles";

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { user } = getLoginData();
  const { headerText, isOperator } = useRoles();
  return (
    <HeaderComponent
      isAuthenticated={isAuthenticated}
      title="Цифровой контроль фасовки"
      position={headerText()}
      name={!isOperator() ? user?.username : ""}
    />
  );
};
export default Header;
