import {
  type DetailedHTMLProps,
  forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
} from "react";
import { Col, InputNumber, Row, Slider } from "antd";
import "./styles/Slider.scss";
import { type SliderComponentProps } from "../../../config/types/types";

export const SliderComponent = forwardRef<
  HTMLInputElement,
  PropsWithChildren<SliderComponentProps> &
    DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement>
>((props, refInput) => {
  const {
    min = 1,
    max = 20,
    disabled = false,
    defaultValue,
    className,
    inputClassName,
    inputValue = 1,
    onChange,
  } = props;

  return (
    <Row>
      <Col span={12}>
        <Slider
          min={min}
          step={props.step}
          max={max}
          onChange={onChange}
          value={typeof inputValue === "number" ? inputValue : 1}
          defaultValue={defaultValue}
          disabled={disabled}
          className={className}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          ref={refInput}
          min={min}
          max={max}
          style={{ margin: "0 16px" }}
          value={inputValue}
          onChange={onChange}
          className={inputClassName}
        />
      </Col>
    </Row>
  );
});

SliderComponent.displayName = "SliderComponent";
