import React, { Suspense } from "react";
import { AppRouter } from "./providers/router";
import "./styles/index.scss";
import { Header } from "../widgets/Header";
import { SideBar } from "../widgets/SideBar";
import { useIncludesLocal } from "../shared/lib/helpers/useIncludesLocal";
import { MainLayout } from "../shared/layouts/MainLayout";
import useRoles from "./providers/router/lib/useRoles";

const App: React.FC = () => {
  const isPrivatePage = useIncludesLocal();
  const { isOperator } = useRoles();
  return (
    <div className="App">
      <div className="content-page">
        <Suspense fallback="">
          <MainLayout
            header={<Header />}
            content={<AppRouter />}
            sidebar={<SideBar />}
            isPrivatePage={!isOperator() && isPrivatePage}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default App;
