import React, { forwardRef } from "react";
import { DatePicker } from "antd";
import clsx from "clsx";
import "./styles/RangePicker.scss";
import { type IRangePickerProps } from "../../../config/types/types";
import styles from "./RangePicker.module.scss";

const { RangePicker } = DatePicker;

export const RangePickerComponent: React.FC<IRangePickerProps> = forwardRef(
  (props: Readonly<IRangePickerProps>, ref: any) => {
    const { className, popupClassName, view = "white", ...others } = props;
    const rootClassName = clsx(className, { [styles[`root_${view}`]]: view });
    const popupRangePickerClassName = clsx(popupClassName, {
      [styles[`dropdown_${view}`]]: view,
    });

    return (
      <RangePicker
        {...others}
        ref={ref}
        className={rootClassName}
        popupClassName={popupRangePickerClassName}
      />
    );
  },
);

RangePickerComponent.displayName = "RangePickerComponent";
