import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./app/providers/AuthProvider/AuthProvider";
import ConfigProvider from "./app/providers/ConfigProvider/ConfigProvider";
import { LineWarningsProvider } from "./app/providers/LineWarningsProvider/LineWarningsProvider";
import Hls from "hls.js";

window.Hls = Hls;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <ConfigProvider>
      <AuthProvider>
        <LineWarningsProvider>
          <App />
        </LineWarningsProvider>
      </AuthProvider>
    </ConfigProvider>
  </BrowserRouter>,
);
