import React, { type ReactElement, type ReactNode, useState } from "react";
import clsx from "clsx";
import { IoIosArrowForward } from "react-icons/io";
import styles from "./sideWrapper.module.scss";
import { Button } from "../../Button";
import type SideBarProps from "../config/types";

export const SideBarWrapper = ({
  className,
  children,
  arrowIconSize = 16,
}: Readonly<SideBarProps>): ReactElement => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onToggle = (): void => {
    setCollapsed((prev) => !prev);
  };

  const childrenWithProps = React.Children.map(
    children,
    (child: ReactNode & { collapsed?: boolean }) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { collapsed });
      }
      return child;
    },
  );

  return (
    <article
      className={clsx(styles.root, className, {
        [styles.rootClosed]: collapsed,
      })}
    >
      <nav className={styles.wrapperElements}>{childrenWithProps}</nav>
      <footer className={styles.footer}>
        <Button
          withoutIndentation
          view="transparent"
          className={styles.closeSideBar}
          onClick={onToggle}
        >
          <IoIosArrowForward
            size={arrowIconSize}
            className={clsx(styles.arrow, { [styles.arrow_rotate]: collapsed })}
          />
        </Button>
      </footer>
    </article>
  );
};
