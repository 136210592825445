import { Navigate, useLocation } from "react-router-dom";
import { useMemo } from "react";
import {
  getRouteLogin,
  getRouteNotFound,
} from "../../../../shared/const/router";
import { useAuth } from "../../../../shared/config/context/useAuth";

export enum UserRole {
  APPARATCHIK = "Main_operator",
  LINE_OPERATOR = "Operator",
}

interface RequireAuthProps {
  children: JSX.Element;
  roles?: UserRole[];
}

export const RequireAuth = ({ children, roles }: RequireAuthProps) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const userRoles: UserRole[] = [UserRole.APPARATCHIK, UserRole.LINE_OPERATOR];

  const hasRequiredRoles = useMemo(() => {
    if (!roles?.length) {
      return true;
    }

    return roles?.some((requiredRole) => {
      const hasRole = userRoles?.includes(requiredRole);
      return hasRole;
    });
  }, [roles, userRoles]);

  if (!isAuthenticated) {
    return <Navigate to={getRouteLogin()} state={{ from: location }} replace />;
  }

  if (!hasRequiredRoles) {
    return (
      <Navigate to={getRouteNotFound()} state={{ from: location }} replace />
    );
  }

  return children;
};
