import { type FC } from "react";
import styles from "./Pagination.module.scss";
import { Button } from "../../Button";
import { type IPagination } from "../../../config/types/types";
import { Typography } from "../../Typography";

export const Pagination: FC<IPagination> = ({
  onChange,
  currPage,
  totalPages = 1,
}): JSX.Element => {
  const onNext = (): void => {
    onChange(currPage + 1);
  };
  const onPrev = (): void => {
    onChange(currPage - 1);
  };

  return (
    <form className={styles.root}>
      <Button type="button" onClick={onNext} disabled={currPage === totalPages}>
        {">"}
      </Button>
      {totalPages > 0 && (
        <Typography className={styles.text}>/ {totalPages}</Typography>
      )}
      <input
        type="number"
        className={styles.input}
        value={currPage}
        onChange={(event) => {
          if (
            Number(event.target.value) <= totalPages &&
            Number(event.target.value) >= 1
          ) {
            onChange(Number(event.target.value));
          }
        }}
      />
      <Button type="button" disabled={currPage === 1} onClick={onPrev}>
        {"<"}
      </Button>
    </form>
  );
};
