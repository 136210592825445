import "./styles/Modal.scss";
import { Modal } from "antd";
import { type FC } from "react";
import { type ModalProps } from "../../../config/types/types";

export const ModalComponent: FC<ModalProps> = ({
  isModalOpen,
  className,
  children,
  onClose,
  destroyOnClose = false,
  ...others
}) => {
  return (
    <Modal
      {...others}
      className={className}
      open={isModalOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose={destroyOnClose}
    >
      {children}
    </Modal>
  );
};
