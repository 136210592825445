import { type ReactElement } from "react";
import clsx from "clsx";
import type BadgeProps from "../config/types";
import styles from "./badge.module.scss";
import { Typography } from "../../Typography";

export const Badge = ({
  warningsCount,
  className,
}: Readonly<BadgeProps>): ReactElement => {
  return (
    <Typography
      className={clsx(styles.root, { [className as string]: className })}
    >
      {warningsCount}
    </Typography>
  );
};
