import { useEffect, useState } from "react";

export const useGetDate = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [date]);

  const time = date.toLocaleTimeString();
  const dateNow = date.toLocaleDateString();

  return { time, dateNow };
};
