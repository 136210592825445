import $api from "../../../shared/api";
import getLoginData from "../../../shared/lib/helpers/getLoginData";

const useInterceptors = (): void => {
  $api.interceptors.request.use((value): any => {
    const loginData = getLoginData();
    if (loginData?.access_token) {
      return {
        ...value,
        headers: {
          Authorization: loginData.access_token,
        },
      };
    }
    return {
      ...value,
    };
  });
};

export default useInterceptors;
