import getLoginData from "../../../../shared/lib/helpers/getLoginData";
import { UserRole } from "../ui/RequireAuth";
import { useLocation } from "react-router-dom";

const headerTextConfig: Record<string, string> = {
  [UserRole.LINE_OPERATOR]: "Оператор линии",
  [UserRole.APPARATCHIK]: "Аппаратчик",
};

const useRoles = (): {
  isApparatchik: () => boolean;
  isOperator: () => boolean;
  headerText: () => string;
} => {
  const localData = getLoginData();
  const locale = useLocation();

  const isApparatchik = (): boolean => {
    return localData?.user?.role?.roleName === UserRole.APPARATCHIK;
  };
  const isOperator = () => locale.pathname.includes("/operator/station");

  const headerText = (): string => {
    if (isApparatchik() && !isOperator()) {
      return headerTextConfig[UserRole.APPARATCHIK];
    }
    return headerTextConfig[UserRole.LINE_OPERATOR];
  };

  return { isApparatchik, isOperator, headerText };
};

export default useRoles;
