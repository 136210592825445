import React, {
  type DetailedHTMLProps,
  forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
} from "react";
import clsx from "clsx";
import styles from "./Textarea.module.scss";
import { type TextareaProps } from "../../../config/types/types";
import { Typography } from "../../Typography";

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  PropsWithChildren<TextareaProps> &
    DetailedHTMLProps<HTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
>((props, refInput): JSX.Element => {
  const {
    className,
    disabled = false,
    placeholder,
    errors,
    wrapperClassName,
    ...others
  } = props;
  return (
    <div className={clsx(styles.textareaWrapper, wrapperClassName)}>
      <textarea
        ref={refInput}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        {...others}
      />
      {errors && (
        <Typography
          component="span"
          type="error"
          className={styles.inputPosition}
        >
          {errors.message}
        </Typography>
      )}
    </div>
  );
});

Textarea.displayName = "Textarea";
