import React from "react";
import clsx from "clsx";
import styles from "./DashboardLayout.module.scss";
import { type DashboardLayoutProps } from "../../../config/types/types";

export const DashboardLayout = ({
  content,
  title = "Линия",
  headerClassName,
  contentClassName,
  view = "primary",
}: DashboardLayoutProps) => {
  const headerClassNames = clsx(styles.cardHeader, headerClassName, {
    [styles[`cardHeader_${view}`]]: view,
  });
  const contentClassNames = clsx(styles.cardBody, contentClassName, {
    [styles[`cardBody_${view}`]]: view,
  });

  return (
    <section className={styles.root}>
      <header className={headerClassNames}>{title}</header>
      {content && <section className={contentClassNames}>{content}</section>}
    </section>
  );
};
