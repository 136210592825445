import { useLocation } from "react-router-dom";
import { routeConfig } from "../../../app/providers/router/config/routeConfig";

export const useIncludesLocal = (): boolean => {
  const { pathname } = useLocation();

  const result = Object.values(routeConfig).find(
    (local) => local.authOnly && local.path?.includes(pathname),
  );

  return Boolean(result);
};
