import React, { createContext, useState } from "react";
import { type AuthContextType } from "../../../shared/types/login";
import getLoginData from "../../../shared/lib/helpers/getLoginData";
import useInterceptors from "../lib/useInterceptors";

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setAuth: () => {},
  loginData: getLoginData(),
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useInterceptors();
  const loginData = getLoginData();
  const [isAuthenticated, setAuth] = useState<boolean>(
    !!loginData?.user?.role?.roleName,
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuth,
        loginData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
