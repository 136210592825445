import { type AppRoutesProps } from "../../../../shared/types/router";
import {
  AppRoutes,
  getRouteBoxDefects,
  getRouteConsistency,
  getRouteDashboard,
  getRouteDashboardOperator,
  getRouteLabel,
  getRouteLogin,
  getRouteMainDashboard,
  getRouteNotFound,
  getRoutePackageDistribution,
  getRouteReports,
} from "../../../../shared/const/router";
import { LoginPage } from "../../../../pages/LoginPage";
import { NotFoundPage } from "../../../../pages/NotFoundPage";
import { UserRole } from "../ui/RequireAuth";
import { BoxDefectsPage } from "../../../../pages/BoxDefectsPage";
import { ConsistencyPage } from "../../../../pages/ConsistencyPage";
import { LabelPage } from "../../../../pages/LabelPage";
import { PackageDistributionPage } from "../../../../pages/PackageDistributionPage";
import { ReportsPage } from "../../../../pages/ReportsPage";
import { DashboardPage } from "../../../../pages/DashboardPage";

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.LOGIN]: {
    path: getRouteLogin(),
    element: <LoginPage />,
  },
  [AppRoutes.BOX_DEFECTS]: {
    path: getRouteBoxDefects(),
    element: <BoxDefectsPage />,
    authOnly: true,
    roles: [UserRole.APPARATCHIK],
  },
  [AppRoutes.CONSISTENCY]: {
    path: getRouteConsistency(),
    element: <ConsistencyPage />,
    authOnly: true,
    roles: [UserRole.APPARATCHIK],
  },
  [AppRoutes.LABEL]: {
    path: getRouteLabel(),
    element: <LabelPage />,
    authOnly: true,
    roles: [UserRole.APPARATCHIK],
  },
  [AppRoutes.PACKEGE_DISTRIBUTION]: {
    path: getRoutePackageDistribution(),
    element: <PackageDistributionPage />,
    authOnly: true,
    roles: [UserRole.APPARATCHIK],
  },
  [AppRoutes.REPORTS]: {
    path: getRouteReports(),
    element: <ReportsPage />,
    authOnly: true,
    roles: [UserRole.APPARATCHIK],
  },
  [AppRoutes.DASHBOARD]: {
    path: getRouteDashboard(),
    element: <DashboardPage />,
    authOnly: true,
    roles: [UserRole.LINE_OPERATOR, UserRole.APPARATCHIK],
  },
  [AppRoutes.MAIN_DASHBOARD]: {
    path: getRouteMainDashboard(),
    element: <DashboardPage />,
    authOnly: true,
    roles: [UserRole.LINE_OPERATOR, UserRole.APPARATCHIK],
  },
  [AppRoutes.NOT_FOUND]: {
    path: getRouteNotFound(),
    element: <NotFoundPage />,
  },
  [AppRoutes.DASHBOARD_OPERATOR]: {
    path: getRouteDashboardOperator(),
    element: <DashboardPage />,
  },
};
