import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineTag } from "react-icons/ai";
import { LuPackageOpen, LuPackageX } from "react-icons/lu";
import { MdOutlineWaterDrop } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";

import {
  getRouteBoxDefects,
  getRouteConsistency,
  getRouteDashboard,
  getRouteLabel,
  getRoutePackageDistribution,
  getRouteReports,
} from "../../../../shared/const/router";
import { type IMenuItem } from "../types/IMenuItem";

export const MENU_ITEMS_CONFIG: IMenuItem[] = [
  {
    key: "dashboard",
    text: "Дашборд",
    path: getRouteDashboard(),
    icon: <RxDashboard />,
    warningsCount: 0,
  },
  {
    key: "packageDistribution",
    text: "Распределение пакета",
    path: getRoutePackageDistribution(),
    icon: <LuPackageOpen />,
    warningsCount: 0,
  },
  {
    key: "consistency",
    text: "Консистенция",
    path: getRouteConsistency(),
    icon: <MdOutlineWaterDrop />,
    warningsCount: 0,
  },
  {
    key: "label",
    text: "Этикетка",
    path: getRouteLabel(),
    icon: <AiOutlineTag />,
    warningsCount: 0,
  },
  {
    key: "boxDefects",
    text: "Дефекты короба",
    path: getRouteBoxDefects(),
    icon: <LuPackageX />,
    warningsCount: 0,
  },
  {
    key: "reports",
    text: "Отчеты",
    path: getRouteReports(),
    icon: <TbReportAnalytics />,
    warningsCount: 0,
  },
];
