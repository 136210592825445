import { FaArrowLeft, FaArrowRight, FaPause, FaPlay } from "react-icons/fa";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

export const videoControlItems = {
  ArrowLeftTitle: "Предыдущий фрагмент",
  ArrowLeftIcon: FaArrowLeft,
  ArrowRightTitle: "Следующий фрагмент",
  ArrowRightIcon: FaArrowRight,
  FullScreenButtonTitle: "Выйти из полноэкранного режима",
  FullScreenButtonIcon: AiOutlineFullscreenExit,
  DefaultedScreenButtonTitle: "Полноэкранный режим",
  DefaultedScreenButtonIcon: AiOutlineFullscreen,
  PlayButtonTitle: "Остановить видео",
  PlayButtonIcon: FaPause,
  PauseButtonTitle: "Воспроизвести видео",
  PauseButtonIcon: FaPlay,
};
